///*------------------------------------*\
//    #LAYOUTS - PAGE CHECKOUT
//\*------------------------------------*/

// page cart
.view-commerce-cart-form {

  table { margin: 0 0 var(--sp-unit); }
  .views-field-line-item-title { font-weight: 700; }
  .form-actions { margin-top: var(--sp-double); }
  #edit-submit[value="Atualizar"] { display: none; }
}



// 
// elements
#edit-commerce-payment-payment-method {
  border: 2px solid var(--cl-secondary-light);
  
  > .form-item {
    border-bottom: 2px solid var(--cl-secondary-light);
    
    &:last-child { border-bottom: 0; }
    &:hover { background-color: var(--cl-secondary-light); }
  }
  
  .option {
    font-weight: 700;
    color: var(--cl-secondary);
  }
}

#card_expiration_wrapper .form-item input, 
#card_expiration_wrapper .form-item select { width: 100%; }
#card_expiration_wrapper {
  @include clearfix;
  margin-bottom: var(--sp-unit);
  
  > .form-item { @include column(1/2); }
}

.form-item-commerce-payment-payment-details-doc-number { clear: both; }


// 
// blocks
// fieldset.cart_contents { grid-area: info; }
// fieldset.commerce_payment { grid-area: pag; }
// fieldset.checkout-buttons { grid-area: btns; }

.cart_contents {
  padding-left: var(--sp-quad);
  
  table { margin-top: 0; }
}

.checkout-buttons {
  .fieldset-wrapper {
    @include horizontal;
    flex-flow: column;
  }
  
  .button-operator { margin: em(12px) 0; }
}


// 
// regions
.page-checkout {
  fieldset {
    padding: 0;
    border: 0;
    margin: 0;
  }
  
  .commerce_payment { margin-bottom: var(--sp-double); }
  .o-main__read { margin-bottom: 0; }
  
  // @include media('screen', '>medium') {
  //   .cart_contents {
  //       padding-left: var(--sp-quad);
  //   }
  // }
}

// .page-cart-order-complete .o-main__read .o-main__header + div {
//   grid-column: 2 / 5;
// }



// @include media('screen', '>medium') {
//   #commerce-checkout-form-checkout>div {
//     display: grid;
//     grid-template-columns: repeat(4, minmax(em(64px), em(260px)));
//     grid-template-areas: 
//       "pag  pag  pag  info info"
//       ".    btns btns .    .";
//   }
  
//   .commerce_payment {
//     legend {
//       @include span(1/3, 0, 0, 0);
//       padding: 0;
//     }

//     .fieldset-wrapper { @include span(2/3, 0, 0, 0); }
//   }
// }

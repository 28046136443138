/*------------------------------------*\
  #COMPONENTS - USER MENU
\*------------------------------------*/

// 
// elements
.c-user-menu__link {
  @include horizontal;
  line-height: $line-height-reset;

  &::before {
    content: '';
    display: inline-block;
    @include size(em(8px), em(6px));
    background: url('#{$imgPath}/arrow-right-brown.svg') center no-repeat;
    margin-right: var(--sp-half);
  }

  &--cta, 
  &--go, 
  &--logout {
    font-family: $roboto;
    font-weight: 700;
  }

  &--cta {
    color: var(--cl-tertiary);
    margin-bottom: var(--sp-quarter);

    &::before {
      @include size(em(20px), em(20px));
      background-image: url('#{$imgPath}/publish-icon-orange.svg');
    }
  }

  &--go {
    border-bottom: 2px solid var(--cl-clean);
    padding-bottom: var(--sp-unit);
    margin-bottom: var(--sp-quarter);

    &::before {
      @include size(em(20px), em(20px));
      background-image: url('#{$imgPath}/user-icon.svg');
      background-color: var(--cl-secondary);
      background-size: 112% auto;
      border-radius: $border-radius-circle;
    }
  }

  &--title {
    @include label;
    margin: var(--sp-unit) 0 var(--sp-quarter);

    &::before {
      @include size(em(16px), em(16px));
      background-image: url('#{$imgPath}/confg-icon-grey.svg');
    }
  }

  &--logout {
    padding: em(14px) 0 em(12px);
    margin-top: em(6px);
    border-top: 2px solid var(--cl-clean);

    &::before {
      @include size(em(20px), em(20px));
      background-image: url('#{$imgPath}/logout-icon-brown.svg');
    }
  }
}

.c-user-menu__item {
  padding: var(--sp-quarter) var(--sp-unit);

  &:not(:last-child) { margin-bottom: var(--sp-half); }
}


// 
// blocks
.block-menu-menu-user-dropdown {
  display: none;
  @include box-dropdown;
  position: absolute;
  top: em(62px);
  right: 0;
  z-index: 5;
  
  .c-user-menu.open & { display: block; }
  .page-user & { top: em(75px); }
}


// 
// region
.c-user-menu {
  position: relative;
  display: none;
  
  &__title {
    color: var(--cl-primary);
    font-size: var(--fz-xm);
    padding: var(--sp-half) var(--sp-unit);
    margin-bottom: var(--sp-unit);
    background-color: var(--cl-clean);
  }

  @include media('screen', '>medium') {
    display: block;
  }
}

/*------------------------------------*\
  #COMPONENTS - CART HEADER
\*------------------------------------*/

//#edit-cart-contents tr, 
#edit-cart-contents tr, 
#views-form-commerce-cart-form-default tr { background-color: $t; }

//#edit-cart-contents td, 
#edit-cart-contents td, 
#views-form-commerce-cart-block-default td, 
#views-form-commerce-cart-form-default td {
  padding: 0;
  border: 0;
}

// 
// elements
.view-commerce-cart-block caption { @include visually-hidden; }

.price, 
.line-item-total-raw {
  font-family: $roboto;
  font-weight: 700;
  line-height: $line-height-reset;
  color: var(--cl-tertiary);
}

.line-item-total-label { @include label; }
.line-item-total { text-align: right; }

.line-item-summary-checkout { border-left: 2px solid var(--cl-secondary-xlight); }

.views-field-field-article-reference {
  font-size: var(--fz-s);
  line-height: 1.4;
  margin-top: var(--sp-half);
}


// 
// blocks
.cart-empty-block { padding: var(--sp-unit); }

.block-commerce-cart-cart {
  display: none;
  @include box-dropdown;
  position: absolute;
  top: em(62px);
  right: 0;
  z-index: 5;
  
  .c-cart-header.open & { display: block; }
  
  // .page-user & { top: em(75px); }
  
  @include media('screen', '<=xsmall') {
    width: em(280px);
  }
}

.cart-default {
  tbody { border: 0; }

  tr {
    padding: var(--sp-half) 0;
    border-bottom: 2px solid var(--cl-secondary-light);
    background-color: $t;
  }

  // grid line for the content cart
  &--item {
    display: grid;
    grid-template-columns: auto var(--sp-double);
    grid-gap: var(--sp-quarter);
    grid-template-areas:
      "tit del"
      "val del"
      "art art";
  }
}
// itens for the content card
// set the grid position
.views-field-line-item-title { grid-area: tit; }
.views-field-commerce-total { grid-area: val; }
.views-field-field-article-reference { grid-area: art; }

.views-field-edit-delete {
  grid-area: del;
  align-self: center;
}

.c-cart-header {
//  grid-area: cart;
  @include size(em(50px));
  position: relative;
  
  &__title {
    font-size: var(--fz-xm);
    color: var(--cl-grey-50);
    padding: var(--sp-half) var(--sp-unit);
    background-color: var(--cl-clean);
  }
  
  // grid line for the content cart
  .cart-default--item {
    padding-left: var(--sp-unit);
    padding-right: var(--sp-unit);
    border-bottom-color: var(--cl-clean);
  }
  
  .line-item-total-raw {
    padding-right: var(--sp-unit);
  }
  
  .links {
    @include horizontal;
    margin-top: var(--sp-unit);
    
    li {
      width: 50%;
      padding: 0;
    }
  }
}

.commerce-price-formatted-components {
  td {
    text-align: right;
    padding: var(--sp-half) 0 $i;
  }

  .component-total {
    font-weight: bold;
    width: em(100px);
  }
}

///*------------------------------------*\
//    #LAYOUTS - PAGE JOURNAL
//\*------------------------------------*/

.page-book .o-main__read, 
.page-journal .o-main__read { grid-column: 2 / span 7 ; }

// .page-book .c-title-page, 
.page-journal .c-title-page { max-width: 50%; }

.page-book .entity-book, 
.page-journal .entity-journal { grid-column: 1 / span 6; }

.page-book .field-name-field-jour-status, 
.page-journal .field-name-field-jour-status { margin-bottom: 16px; }

.page-book .field-name-field-jour-articles .field-item, 
.page-journal .field-name-field-jour-articles .field-item { margin-bottom: 16px; }

///*------------------------------------*\
//    #LAYOUTS - PAGE USER
//\*------------------------------------*/

// 
// elemnts
.chosen-container-single .chosen-single div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chosen-container-single .chosen-single div b {
  @include size(18px);
  background-position: 4px 0;
}


// page order
.entity-commerce-order .field-name-commerce-order-total { display: $n; }
.entity-commerce-order .field-name-commerce-line-items { margin-bottom: var(--sp-double); }

.entity-commerce-order .field-name-field-ticket-link .field-label { display: block; }
.entity-commerce-order .field-name-field-ticket-link {
  padding: var(--sp-unit);
  background-color: var(--cl-secondary-light);
}
//// page order

// profile
.profile {
  margin: 0;
  
  p:first-child { margin-top: 0; }
  p:last-child { margin-top: 0; }
}

.profile .field { margin-bottom: var(--sp-unit); }

.form-type-password-confirm label { display: block; }
div.form-item div.password-suggestions { width: 100%; }

@include media('screen', '<=medium') {
  .confirm-parent, 
  .password-parent { width: $in; }
  
  div.password-confirm, 
  .password-strength {
    float: $in;
    margin-bottom: 1.4em;
  }
}


// 
// blocks
.view-commerce-backoffice-user-orders .views-table { margin: 0; }
.view-commerce-backoffice-user-orders tr { padding: 16px 8px; }
.view-commerce-line-item-table .views-field-line-item-title { width: em(140px); }
// page edit count
#user-profile-form #edit-cancel { float: right; }


// 
// regions
// .page-user {
  // .c-cart-header, 
  // .c-user-menu {
  //     padding-top: var(--sp-unit);
  // }
  
  // .o-main { margin-bottom: 0; }
  
  // .o-main__read {
  //     padding-bottom: var(--sp-quad);
  // }
  
  // .o-sidebar-first {
  //   display: $n;
  //   margin-bottom: 0;
  //     background-color: var(--cl-secondary-xlight);
  //     background-clip: content-box;
  // }
  
  // .c-user-fixed { // loggedin
  //     padding-bottom: var(--sp-quad);
  // }
  
  
  // Desktop
  // @include media('screen', '>medium') {
  //   &::before, 
  //   &::after {
  //     content: '';
  //     display: block;
  //     height: 100%;
  //     position: fixed;
  //     top: 0;
  //     z-index: -1;
  //   }

  //   &::before {
  //     width: 60%;
  //       background-color: var(--cl-clean);
  //     left: 0;
  //   }

  //   &::after {
  //     width: calc(40% - 4rem);
  //       background-color: var(--cl-secondary-xlight);
  //     right: 0;
  //   }
    
  //   &.logged-in {
  //     .o-sidebar-first { display: block; }
      
  //     // .o-header__top, 
  //     .o-wrapper, 
  //     .o-main {
  //       position: relative;

  //       &::before, 
  //       &::after {
  //         content: '';
  //         display: block;
  //         height: 100%;
  //         position: absolute;
  //         top: 0;
  //         z-index: -1;
  //       }

  //       &::before {
  //         width: 60%;
  //           background-color: var(--cl-clean);
  //         left: 0;
  //       }

  //       &::after {
  //         width: calc(40% - 4rem);
  //           background-color: var(--cl-secondary-xlight);
  //         right: 0;
  //       }
  //     }
  //   }
  // }
// }

///*------------------------------------*\
//    #LAYOUTS - PAGE REGISTER
//\*------------------------------------*/

// 
// elements
#edit-field-user-type-und {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: em(8px);
  
  label.option {
    font-weight: 700;
    color: var(--cl-secondary);
    font-size: var(--fz-xm);
  }
  
  .form-item:not(:first-child) { margin-left: var(--sp-unit); }
}

.group-user-type .form-type-checkboxes, 
//.group-curriculo {}
.form-item-field-user-lattes-und-0-url { margin-bottom: 0; }

.form-item-field-user-address-und-0-thoroughfare, 
.form-item-field-user-address-und-0-sub-premise, 
.form-item-field-user-address-und-0-dependent-locality, 
.form-item-field-user-address-und-0-administrative-area { margin-right: 0 $i; }

.br-adress-logradouro-bairro {
  .form-item-field-user-address-und-0-premise { width: calc(50% - 1em); }
  .form-item-field-user-address-und-0-sub-premise { width: 50%; }
  .form-item-field-user-address-und-0-dependent-locality { width: 100%; }
}

fieldset#edit-legal legend { padding-right: em(12px); }
#edit-legal .form-item { margin-bottom: 0; }

#edit-conditions {
  color: var(--cl-grey-50);
  font-size: var(--fz-s);
  line-height: $line-height-text;
}

.form-item-legal-accept {
  @include horizontal;
  padding: em(12px) var(--sp-unit);
  background-color: var(--cl-secondary-light);
  
  .form-checkbox { margin-right: var(--sp-half); }
  
  .option { color: var(--cl-secondary); }
}


// 
// blocks
#user-register-form {
  @include clearfix();
  
  fieldset {
    border: 0;
    margin-top: 0;
    padding: var(--sp-half) 0 0;
    border-top: 2px solid var(--cl-grey-20);
  }
  
  .captcha { margin-bottom: var(--sp-and-half); }
  
  // Desktop
  @include media('screen', '>medium') {
    #edit-account, 
    .captcha, 
    .url-textfield, 
    .form-actions { @include span(2/3, 1/3, 0, 0); }
    
    fieldset {
      @include span(3/3);
      padding: var(--sp-and-half) 0 0;
    }
    
    legend {
      @include span(1/3, 0, 0, 0);
      padding: 0;
    }
    
    .fieldset-wrapper { @include span(2/3, 0, 0, 0); }
  }
}

.o-cta-call-user {
  .block__title  {
    color: var(--cl-primary);
    max-width: em(200px);
    font-size: var(--fz-xxl);
    line-height: $line-height-reset;
  }
  
  h5 { font-size: var(--fz-xl); }
  
  li {
    display: grid;
    grid-template-columns: minmax(24px, auto) auto;
    grid-gap: em(8px);
    align-items: center;
    margin-top: var(--sp-unit);
    
    p {
      grid-column: 2;
      grid-row: 2;
    }
    
    &::before {
      color: var(--cl-tertiary);
      font-size: var(--fz-xl);
      line-height: $line-height-reset;
    }
    
    &::after {
      content: '';
      display: block;
      grid-column: 1;
      grid-row: 2;
      justify-self: center;
      @include size(2px, 100%);
      background-color: var(--cl-tertiary);
    }
    
    &:last-child::after { display: $n; }
  }
}


// 
// regions
.page-user-register {
  .o-cta-call-user {
    padding-left: var(--sp-double);
    padding-bottom: var(--sp-quad);
  }
  
  
  @include media('screen', '>medium') {
    .o-sidebar-first { display: block; }
  }
}


// Desktop
@include media('screen', '>medium') {
  .br-address-cidade-estado > .form-item { @include column(1/2, 0, 2, 3); }
}

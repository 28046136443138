///*------------------------------------*\
//    #LAYOUTS - PRODUCT FORM
//\*------------------------------------*/

// 
// elements
.form-type-radio.form-item-field-arti-revision-type-und {
  cursor: pointer;
  padding: em(12px) $i;
  border: 2px solid var(--cl-secondary-light);

  label { cursor: pointer; }

  &:hover { border-color: var(--cl-primary); }
}
.form-item-field-arti-revision-type-und {
  // zerar
  float: $in;
  min-width: $in;
  padding: 0 $i;
  margin: 0 $i;
  // zerar

  ul { @include clear-list; }
  strong { font-weight: 400; }
  p strong { font-weight: 700; }
  
  li:not(:last-child) { margin-bottom: var(--sp-half); }
  
  p {
    font-weight: 700;
    color: var(--cl-primary);
    font-size: var(--fz-xxm);
    margin: 0 0 var(--sp-half);
  }

  .form-radio { margin: em(2px) em(8px) 0 0; }
  #edit-field-arti-revision-type-und & { display: flex; }

  @include media('screen', '>=small') {
    .form-radios {
      display: flex;
      justify-content: space-around;
    }
  }
}

#edit-field-arti-authors {
  margin-bottom: var(--sp-double);
  
  tr { border: 0; }
  .messages { margin-top: 0; }
  
  .field-label {
    padding-bottom: em(8px);
    border: 0;
  }
  
  a.tabledrag-handle {
    float: $n;
    display: block;
    @include size(em(50px));
    margin: 0;
    padding: 0;
    background: $t url('#{$imgPath}/user-icon.svg') center no-repeat;
    background-color: var(--cl-secondary-light);
  }
  
  td.field-multiple-drag { position: relative; }
  
  td .tabledrag-changed {
    @include centerer(left);
  }
  
  .field-add-more-submit {
    float: right;
    margin-top: 0;
  }
}

#edit-field-arti-summary { margin-bottom: var(--sp-double); }

fieldset.filter-wrapper.form-wrapper,
a.ckeditor_links { display: $n; }



.node-article-form {
  legend, 
  label[for="edit-field-arti-revision-type-und"] { display: $n; }
  
  fieldset {
    padding: 0;
    border: 0;
    margin-top: 0;
  }
  
  #edit-preview { margin-left: auto; }
}

#clientsidevalidation-article-node-form-errors {
  display: block;
  position: fixed;
  bottom: 12px;
  left: 12px;
  right: 12px;
  margin: 0px auto;
  z-index: 5;
}

.page-meus-artigos {
  .o-product-list--item {
    margin-bottom: var(--sp-quad);

    .button { margin: 8px 0; }
    .c-product-teaser__certification { margin-bottom: 12px; }
  }
}

:root {
  --cl-clean: #fff;
  --cl-dark: #000;
  --cl-text: #4a4a4f;
  --cl-link-visited: #847A5A;
  --cl-grey-10: #f9f9fa;
  --cl-grey-20: #eeeef1;
  --cl-grey-30: #d7d7db;
  --cl-grey-40: #b2b2b3;
  --cl-grey-50: #737373;
  --cl-grey-60: #4a4a4f;
  --cl-grey-70: #38383d;
  --cl-grey-80: #29292e;
  --cl-grey-90: #0c0c0d;
  --cl-sucess: #13bd00;
  --cl-info: #0a85ff;
  --cl-warning: #d66f00;
  --cl-error: #d60020;
  --cl-primary: #97ab21;
  --cl-secondary: #70653e;
  --cl-tertiary: #e77d04;
  --cl-primary-dark: #687817;
  --cl-secondary-light: #f0efec;
  --cl-secondary-xlight: #f9f9fa;
  --fz-uul: 4.5rem;
  --fz-ul: 4rem;
  --fz-hhhl: 3rem;
  --fz-hhl: 2.75rem;
  --fz-hl: 2.25rem;
  --fz-xxl: 2rem;
  --fz-xl: 1.75rem;
  --fz-l: 1.5rem;
  --fz-xxm: 1.25rem;
  --fz-xm: 1.125rem;
  --fz-m: 16px;
  --fz-s: 0.875rem;
  --fz-xs: 0.75rem;
  --fz-xxs: 0.625rem;
  --sp-quarter: 0.25rem;
  --sp-half: 0.5rem;
  --sp-unit: 1rem;
  --sp-and-half: 1.5rem;
  --sp-double: 2rem;
  --sp-triple: 3rem;
  --sp-quad: 4rem;
}

.chosen-container-single .chosen-single div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chosen-container-single .chosen-single div b {
  width: 18px;
  height: 18px;
  background-position: 4px 0;
}

.entity-commerce-order .field-name-commerce-order-total {
  display: none;
}

.entity-commerce-order .field-name-commerce-line-items {
  margin-bottom: var(--sp-double);
}

.entity-commerce-order .field-name-field-ticket-link .field-label {
  display: block;
}

.entity-commerce-order .field-name-field-ticket-link {
  padding: var(--sp-unit);
  background-color: var(--cl-secondary-light);
}

.profile {
  margin: 0;
}

.profile p:first-child {
  margin-top: 0;
}

.profile p:last-child {
  margin-top: 0;
}

.profile .field {
  margin-bottom: var(--sp-unit);
}

.form-type-password-confirm label {
  display: block;
}

div.form-item div.password-suggestions {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .confirm-parent,
  .password-parent {
    width: initial;
  }
  div.password-confirm,
  .password-strength {
    float: initial;
    margin-bottom: 1.4em;
  }
}

.view-commerce-backoffice-user-orders .views-table {
  margin: 0;
}

.view-commerce-backoffice-user-orders tr {
  padding: 16px 8px;
}

.view-commerce-line-item-table .views-field-line-item-title {
  width: 8.75rem;
}

#user-profile-form #edit-cancel {
  float: right;
}

#edit-field-user-type-und {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

#edit-field-user-type-und label.option {
  font-weight: 700;
  color: var(--cl-secondary);
  font-size: var(--fz-xm);
}

#edit-field-user-type-und .form-item:not(:first-child) {
  margin-left: var(--sp-unit);
}

.group-user-type .form-type-checkboxes,
.form-item-field-user-lattes-und-0-url {
  margin-bottom: 0;
}

.form-item-field-user-address-und-0-thoroughfare,
.form-item-field-user-address-und-0-sub-premise,
.form-item-field-user-address-und-0-dependent-locality,
.form-item-field-user-address-und-0-administrative-area {
  margin-right: 0 !important;
}

.br-adress-logradouro-bairro .form-item-field-user-address-und-0-premise {
  width: calc(50% - 1em);
}

.br-adress-logradouro-bairro .form-item-field-user-address-und-0-sub-premise {
  width: 50%;
}

.br-adress-logradouro-bairro .form-item-field-user-address-und-0-dependent-locality {
  width: 100%;
}

fieldset#edit-legal legend {
  padding-right: 0.75rem;
}

#edit-legal .form-item {
  margin-bottom: 0;
}

#edit-conditions {
  color: var(--cl-grey-50);
  font-size: var(--fz-s);
  line-height: 1.3;
}

.form-item-legal-accept {
  display: flex;
  align-items: center;
  padding: 0.75rem var(--sp-unit);
  background-color: var(--cl-secondary-light);
}

.form-item-legal-accept .form-checkbox {
  margin-right: var(--sp-half);
}

.form-item-legal-accept .option {
  color: var(--cl-secondary);
}

#user-register-form:before {
  content: '';
  display: table;
}

#user-register-form:after {
  content: '';
  display: table;
  clear: both;
}

#user-register-form fieldset {
  border: 0;
  margin-top: 0;
  padding: var(--sp-half) 0 0;
  border-top: 2px solid var(--cl-grey-20);
}

#user-register-form .captcha {
  margin-bottom: var(--sp-and-half);
}

@media screen and (min-width: 993px) {
  #user-register-form #edit-account,
  #user-register-form .captcha,
  #user-register-form .url-textfield,
  #user-register-form .form-actions {
    float: left;
    clear: none;
    text-align: inherit;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: 0%;
  }
  #user-register-form #edit-account:before,
  #user-register-form .captcha:before,
  #user-register-form .url-textfield:before,
  #user-register-form .form-actions:before {
    content: '';
    display: table;
  }
  #user-register-form #edit-account:after,
  #user-register-form .captcha:after,
  #user-register-form .url-textfield:after,
  #user-register-form .form-actions:after {
    content: '';
    display: table;
    clear: both;
  }
  #user-register-form fieldset {
    float: left;
    clear: none;
    text-align: inherit;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    padding: var(--sp-and-half) 0 0;
  }
  #user-register-form fieldset:before {
    content: '';
    display: table;
  }
  #user-register-form fieldset:after {
    content: '';
    display: table;
    clear: both;
  }
  #user-register-form legend {
    float: left;
    clear: none;
    text-align: inherit;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: 0%;
    padding: 0;
  }
  #user-register-form legend:before {
    content: '';
    display: table;
  }
  #user-register-form legend:after {
    content: '';
    display: table;
    clear: both;
  }
  #user-register-form .fieldset-wrapper {
    float: left;
    clear: none;
    text-align: inherit;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: 0%;
  }
  #user-register-form .fieldset-wrapper:before {
    content: '';
    display: table;
  }
  #user-register-form .fieldset-wrapper:after {
    content: '';
    display: table;
    clear: both;
  }
}

.o-cta-call-user .block__title {
  color: var(--cl-primary);
  max-width: 12.5rem;
  font-size: var(--fz-xxl);
  line-height: 1;
}

.o-cta-call-user h5 {
  font-size: var(--fz-xl);
}

.o-cta-call-user li {
  display: grid;
  grid-template-columns: minmax(24px, auto) auto;
  grid-gap: 0.5rem;
  align-items: center;
  margin-top: var(--sp-unit);
}

.o-cta-call-user li p {
  grid-column: 2;
  grid-row: 2;
}

.o-cta-call-user li::before {
  color: var(--cl-tertiary);
  font-size: var(--fz-xl);
  line-height: 1;
}

.o-cta-call-user li::after {
  content: '';
  display: block;
  grid-column: 1;
  grid-row: 2;
  justify-self: center;
  width: 2px;
  height: 100%;
  background-color: var(--cl-tertiary);
}

.o-cta-call-user li:last-child::after {
  display: none;
}

.page-user-register .o-cta-call-user {
  padding-left: var(--sp-double);
  padding-bottom: var(--sp-quad);
}

@media screen and (min-width: 993px) {
  .page-user-register .o-sidebar-first {
    display: block;
  }
}

@media screen and (min-width: 993px) {
  .br-address-cidade-estado > .form-item {
    float: left;
    clear: none;
    text-align: inherit;
    width: 48.5%;
    margin-left: 0%;
    margin-right: 3%;
  }
  .br-address-cidade-estado > .form-item:before {
    content: '';
    display: table;
  }
  .br-address-cidade-estado > .form-item:after {
    content: '';
    display: table;
    clear: both;
  }
  .br-address-cidade-estado > .form-item:nth-child(n) {
    margin-right: 3%;
    float: left;
    clear: none;
  }
  .br-address-cidade-estado > .form-item:nth-child(2n) {
    margin-right: 0%;
    float: right;
  }
  .br-address-cidade-estado > .form-item:nth-child(2n + 1) {
    clear: both;
  }
}

/*------------------------------------*\
  #COMPONENTS - CART HEADER
\*------------------------------------*/
#edit-cart-contents tr,
#views-form-commerce-cart-form-default tr {
  background-color: transparent;
}

#edit-cart-contents td,
#views-form-commerce-cart-block-default td,
#views-form-commerce-cart-form-default td {
  padding: 0;
  border: 0;
}

.view-commerce-cart-block caption {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.price,
.line-item-total-raw {
  font-family: "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  font-weight: 700;
  line-height: 1;
  color: var(--cl-tertiary);
}

.line-item-total-label {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
}

.line-item-total {
  text-align: right;
}

.line-item-summary-checkout {
  border-left: 2px solid var(--cl-secondary-xlight);
}

.views-field-field-article-reference {
  font-size: var(--fz-s);
  line-height: 1.4;
  margin-top: var(--sp-half);
}

.cart-empty-block {
  padding: var(--sp-unit);
}

.block-commerce-cart-cart {
  display: none;
  width: 20rem;
  border: 2px solid var(--cl-secondary);
  background-color: var(--cl-secondary-xlight);
  position: relative;
  position: absolute;
  top: 3.875rem;
  right: 0;
  z-index: 5;
}

.block-commerce-cart-cart::before {
  content: '';
  width: 0;
  height: 0;
  z-index: 2;
  border-bottom: 9px solid #70653e;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  position: absolute;
  top: -0.5625rem;
  right: 1rem;
}

.c-cart-header.open .block-commerce-cart-cart {
  display: block;
}

@media screen and (max-width: 480px) {
  .block-commerce-cart-cart {
    width: 17.5rem;
  }
}

.cart-default tbody {
  border: 0;
}

.cart-default tr {
  padding: var(--sp-half) 0;
  border-bottom: 2px solid var(--cl-secondary-light);
  background-color: transparent;
}

.cart-default--item {
  display: grid;
  grid-template-columns: auto var(--sp-double);
  grid-gap: var(--sp-quarter);
  grid-template-areas: "tit del" "val del" "art art";
}

.views-field-line-item-title {
  grid-area: tit;
}

.views-field-commerce-total {
  grid-area: val;
}

.views-field-field-article-reference {
  grid-area: art;
}

.views-field-edit-delete {
  grid-area: del;
  align-self: center;
}

.c-cart-header {
  width: 3.125rem;
  height: 3.125rem;
  position: relative;
}

.c-cart-header__title {
  font-size: var(--fz-xm);
  color: var(--cl-grey-50);
  padding: var(--sp-half) var(--sp-unit);
  background-color: var(--cl-clean);
}

.c-cart-header .cart-default--item {
  padding-left: var(--sp-unit);
  padding-right: var(--sp-unit);
  border-bottom-color: var(--cl-clean);
}

.c-cart-header .line-item-total-raw {
  padding-right: var(--sp-unit);
}

.c-cart-header .links {
  display: flex;
  align-items: center;
  margin-top: var(--sp-unit);
}

.c-cart-header .links li {
  width: 50%;
  padding: 0;
}

.commerce-price-formatted-components td {
  text-align: right;
  padding: var(--sp-half) 0 !important;
}

.commerce-price-formatted-components .component-total {
  font-weight: bold;
  width: 6.25rem;
}

/*------------------------------------*\
  #COMPONENTS - USER MENU
\*------------------------------------*/
.c-user-menu__link {
  display: flex;
  align-items: center;
  line-height: 1;
}

.c-user-menu__link::before {
  content: '';
  display: inline-block;
  width: 0.5rem;
  height: 0.375rem;
  background: url("../images/arrow-right-brown.svg") center no-repeat;
  margin-right: var(--sp-half);
}

.c-user-menu__link--cta, .c-user-menu__link--go, .c-user-menu__link--logout {
  font-family: "Roboto", Tahoma, "DejaVu Sans", sans-serif;
  font-weight: 700;
}

.c-user-menu__link--cta {
  color: var(--cl-tertiary);
  margin-bottom: var(--sp-quarter);
}

.c-user-menu__link--cta::before {
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("../images/publish-icon-orange.svg");
}

.c-user-menu__link--go {
  border-bottom: 2px solid var(--cl-clean);
  padding-bottom: var(--sp-unit);
  margin-bottom: var(--sp-quarter);
}

.c-user-menu__link--go::before {
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("../images/user-icon.svg");
  background-color: var(--cl-secondary);
  background-size: 112% auto;
  border-radius: 50%;
}

.c-user-menu__link--title {
  text-transform: uppercase;
  color: var(--cl-grey-50);
  font-size: var(--fz-xs);
  font-weight: 700;
  line-height: 1;
  margin: var(--sp-unit) 0 var(--sp-quarter);
}

.c-user-menu__link--title::before {
  width: 1rem;
  height: 1rem;
  background-image: url("../images/confg-icon-grey.svg");
}

.c-user-menu__link--logout {
  padding: 0.875rem 0 0.75rem;
  margin-top: 0.375rem;
  border-top: 2px solid var(--cl-clean);
}

.c-user-menu__link--logout::before {
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("../images/logout-icon-brown.svg");
}

.c-user-menu__item {
  padding: var(--sp-quarter) var(--sp-unit);
}

.c-user-menu__item:not(:last-child) {
  margin-bottom: var(--sp-half);
}

.block-menu-menu-user-dropdown {
  display: none;
  width: 20rem;
  border: 2px solid var(--cl-secondary);
  background-color: var(--cl-secondary-xlight);
  position: relative;
  position: absolute;
  top: 3.875rem;
  right: 0;
  z-index: 5;
}

.block-menu-menu-user-dropdown::before {
  content: '';
  width: 0;
  height: 0;
  z-index: 2;
  border-bottom: 9px solid #70653e;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  position: absolute;
  top: -0.5625rem;
  right: 1rem;
}

.c-user-menu.open .block-menu-menu-user-dropdown {
  display: block;
}

.page-user .block-menu-menu-user-dropdown {
  top: 4.6875rem;
}

.c-user-menu {
  position: relative;
  display: none;
}

.c-user-menu__title {
  color: var(--cl-primary);
  font-size: var(--fz-xm);
  padding: var(--sp-half) var(--sp-unit);
  margin-bottom: var(--sp-unit);
  background-color: var(--cl-clean);
}

@media screen and (min-width: 993px) {
  .c-user-menu {
    display: block;
  }
}

.form-type-radio.form-item-field-arti-revision-type-und {
  cursor: pointer;
  padding: 0.75rem !important;
  border: 2px solid var(--cl-secondary-light);
}

.form-type-radio.form-item-field-arti-revision-type-und label {
  cursor: pointer;
}

.form-type-radio.form-item-field-arti-revision-type-und:hover {
  border-color: var(--cl-primary);
}

.form-item-field-arti-revision-type-und {
  float: initial;
  min-width: initial;
  padding: 0 !important;
  margin: 0 !important;
}

.form-item-field-arti-revision-type-und ul {
  list-style: none;
  padding: 0;
}

.form-item-field-arti-revision-type-und strong {
  font-weight: 400;
}

.form-item-field-arti-revision-type-und p strong {
  font-weight: 700;
}

.form-item-field-arti-revision-type-und li:not(:last-child) {
  margin-bottom: var(--sp-half);
}

.form-item-field-arti-revision-type-und p {
  font-weight: 700;
  color: var(--cl-primary);
  font-size: var(--fz-xxm);
  margin: 0 0 var(--sp-half);
}

.form-item-field-arti-revision-type-und .form-radio {
  margin: 0.125rem 0.5rem 0 0;
}

#edit-field-arti-revision-type-und .form-item-field-arti-revision-type-und {
  display: flex;
}

@media screen and (min-width: 768px) {
  .form-item-field-arti-revision-type-und .form-radios {
    display: flex;
    justify-content: space-around;
  }
}

#edit-field-arti-authors {
  margin-bottom: var(--sp-double);
}

#edit-field-arti-authors tr {
  border: 0;
}

#edit-field-arti-authors .messages {
  margin-top: 0;
}

#edit-field-arti-authors .field-label {
  padding-bottom: 0.5rem;
  border: 0;
}

#edit-field-arti-authors a.tabledrag-handle {
  float: none;
  display: block;
  width: 3.125rem;
  height: 3.125rem;
  margin: 0;
  padding: 0;
  background: transparent url("../images/user-icon.svg") center no-repeat;
  background-color: var(--cl-secondary-light);
}

#edit-field-arti-authors td.field-multiple-drag {
  position: relative;
}

#edit-field-arti-authors td .tabledrag-changed {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

#edit-field-arti-authors .field-add-more-submit {
  float: right;
  margin-top: 0;
}

#edit-field-arti-summary {
  margin-bottom: var(--sp-double);
}

fieldset.filter-wrapper.form-wrapper,
a.ckeditor_links {
  display: none;
}

.node-article-form legend,
.node-article-form label[for="edit-field-arti-revision-type-und"] {
  display: none;
}

.node-article-form fieldset {
  padding: 0;
  border: 0;
  margin-top: 0;
}

.node-article-form #edit-preview {
  margin-left: auto;
}

#clientsidevalidation-article-node-form-errors {
  display: block;
  position: fixed;
  bottom: 12px;
  left: 12px;
  right: 12px;
  margin: 0px auto;
  z-index: 5;
}

.page-meus-artigos .o-product-list--item {
  margin-bottom: var(--sp-quad);
}

.page-meus-artigos .o-product-list--item .button {
  margin: 8px 0;
}

.page-meus-artigos .o-product-list--item .c-product-teaser__certification {
  margin-bottom: 12px;
}

.view-commerce-cart-form table {
  margin: 0 0 var(--sp-unit);
}

.view-commerce-cart-form .views-field-line-item-title {
  font-weight: 700;
}

.view-commerce-cart-form .form-actions {
  margin-top: var(--sp-double);
}

.view-commerce-cart-form #edit-submit[value="Atualizar"] {
  display: none;
}

#edit-commerce-payment-payment-method {
  border: 2px solid var(--cl-secondary-light);
}

#edit-commerce-payment-payment-method > .form-item {
  border-bottom: 2px solid var(--cl-secondary-light);
}

#edit-commerce-payment-payment-method > .form-item:last-child {
  border-bottom: 0;
}

#edit-commerce-payment-payment-method > .form-item:hover {
  background-color: var(--cl-secondary-light);
}

#edit-commerce-payment-payment-method .option {
  font-weight: 700;
  color: var(--cl-secondary);
}

#card_expiration_wrapper .form-item input,
#card_expiration_wrapper .form-item select {
  width: 100%;
}

#card_expiration_wrapper {
  margin-bottom: var(--sp-unit);
}

#card_expiration_wrapper:before {
  content: '';
  display: table;
}

#card_expiration_wrapper:after {
  content: '';
  display: table;
  clear: both;
}

#card_expiration_wrapper > .form-item {
  float: left;
  clear: none;
  text-align: inherit;
  width: 48.5%;
  margin-left: 0%;
  margin-right: 3%;
}

#card_expiration_wrapper > .form-item:before {
  content: '';
  display: table;
}

#card_expiration_wrapper > .form-item:after {
  content: '';
  display: table;
  clear: both;
}

#card_expiration_wrapper > .form-item:last-child {
  margin-right: 0%;
}

.form-item-commerce-payment-payment-details-doc-number {
  clear: both;
}

.cart_contents {
  padding-left: var(--sp-quad);
}

.cart_contents table {
  margin-top: 0;
}

.checkout-buttons .fieldset-wrapper {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.checkout-buttons .button-operator {
  margin: 0.75rem 0;
}

.page-checkout fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}

.page-checkout .commerce_payment {
  margin-bottom: var(--sp-double);
}

.page-checkout .o-main__read {
  margin-bottom: 0;
}

.page-book .o-main__read,
.page-journal .o-main__read {
  grid-column: 2 / span 7;
}

.page-journal .c-title-page {
  max-width: 50%;
}

.page-book .entity-book,
.page-journal .entity-journal {
  grid-column: 1 / span 6;
}

.page-book .field-name-field-jour-status,
.page-journal .field-name-field-jour-status {
  margin-bottom: 16px;
}

.page-book .field-name-field-jour-articles .field-item,
.page-journal .field-name-field-jour-articles .field-item {
  margin-bottom: 16px;
}
